import {cloudcallIdValidator} from "../Helpers/validator";
import exp from "node:constants";
import {Input, Select, SelectProps} from "@cloudscape-design/components";
import {useAppDispatch, useAppSelector} from "../hooks";
import {fetchCustomerConnectors, getAllConnectors} from "../slices/customerConnectors";
import {useEffect, useState} from "react";
import fetchStatus from "../types/fetchStatus";

interface ConnectorSelectorProps {
    onChange?: Function | null,
    customerId: string
    initalValue?: string | null
}

interface Connector {
    id: string
    clientConfiguration: {
        integrationName: string
    }
}

const ConnectorSelector = ( {onChange, customerId, initalValue} : ConnectorSelectorProps) => {


    const dispatch = useAppDispatch()
    const allCustomerConnectors = useAppSelector(getAllConnectors)
    const [selectedConnectorId, setSelectedConnectorId] = useState(initalValue)
    const [selectedOption, setSelectedOption] = useState<SelectProps.Option | null>(null);



    useEffect(() => {
        if (
            cloudcallIdValidator(customerId) &&
            //@ts-ignore
            (allCustomerConnectors[customerId] === undefined || allCustomerConnectors[customerId].state === fetchStatus.failed)
        ) {
            dispatch(fetchCustomerConnectors({customerId: customerId}))
        }
    }, [customerId]);

    useEffect(() => {
        if (onChange && typeof onChange === "function") {
            onChange(selectedConnectorId);
        }
    }, [selectedConnectorId]);

    if (customerId?.length === 0 || !cloudcallIdValidator(customerId)) {
        if(selectedConnectorId !== '') {
            setSelectedConnectorId('')
            setSelectedOption(null)
        }
        return <Select
            selectedOption={{ label: "Please Select or enter a valid customer ID", value: "1" }}
            options={[
                { label: "Please Select or enter a valid customer ID", value: "1" },
            ]}
            disabled
        />
    }
    //@ts-ignore
    const targetCustomer = allCustomerConnectors[customerId];
    if (targetCustomer && targetCustomer.value?.length === 1) {
        if(targetCustomer.value[0].id !== selectedConnectorId) {
            setSelectedConnectorId(targetCustomer.value[0].id)
        }
        return (
            <Input value={targetCustomer.value[0].id} disabled onChange={console.log}/>
        )
    }
    const getState = () => {
        switch (targetCustomer?.state) {
            case fetchStatus.failed:
                return "error";
            case fetchStatus.loading:
                return "loading";
            default:
                if (targetCustomer?.value.length === 0) return "error"
                return "finished";
        }
    }
    const options = targetCustomer?.value?.map((connector: Connector) => {
        return { label: connector.clientConfiguration.integrationName, value: connector.id }
    })
    return (<Select
        selectedOption={selectedOption}
        onChange={({ detail }) => {
            setSelectedOption(detail.selectedOption)
            if(detail.selectedOption.value !== selectedConnectorId) {
                setSelectedConnectorId(detail.selectedOption.value || "")
            }
        }}
        options={options}
        statusType={getState()}
        errorText="Error fetching connectors"
        placeholder="Select a connector"
    />)

}
export default ConnectorSelector;
