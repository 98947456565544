
const REQUIRED_ROLES = ["super_admin","integrations_dashboard_user"];
const ADMIN_ROLE = "super_admin";

type Role = (string | string[] | undefined);
const isRoleValid = (role: Role):boolean => {
    if (!role) return false;
    if (Array.isArray(role)) {
        const found = role.filter((targetRoll:string) => {
            return REQUIRED_ROLES.indexOf(targetRoll) > -1
        });
        if (found.length > 0) return true;
    } else {
        if (REQUIRED_ROLES.indexOf(role) > -1) return true;
    }
    return false
}
const isRoleAdmin = (role: Role):boolean => {
    if (!role) return false;
    if (Array.isArray(role)) {
        return role.indexOf(ADMIN_ROLE) > -1;
    }
    return role === ADMIN_ROLE;
}

export {
    isRoleValid,
    isRoleAdmin
}
