import {
    Alert,
    Button,
    Container,
    FileUpload,
    FormField,
    Header,
    Input, ProgressBar,
    SpaceBetween
} from "@cloudscape-design/components";
import {useEffect, useRef, useState} from "react";
import {ApiHelper} from "../Helpers/apiHelper";
import Loading from "./Loading";
import {Link as DomLink} from "react-router-dom";
import Papa from "papaparse";
import {cloudcallIdValidator, minLengthField} from "../Helpers/validator";
import CrmUser from "../types/crmUser";
import ConnectorSelector from "./ConnectorSelector";
import CustomerIdSelector from "./CustomerIdSelector";


interface ManualMappingProps {
    initialConnectorId?: string | null
    manualMappingUser: CrmUser | null
    setManualMappingUser: Function
}


const ManualMapping = ({initialConnectorId, manualMappingUser, setManualMappingUser}: ManualMappingProps) => {

    const manualMappingRef = useRef(null)
    const [connectorId, setConnectorId] = useState(initialConnectorId || "");
    const [userId, setUserId] = useState("");
    const [crmUserId, setCrmUserId] = useState(manualMappingUser?.id || "");
    const [crmUsername, setCrmUsername] = useState(manualMappingUser?.username || "");
    const [customerId, setCustomerId] = useState( "");
    const [isLoading, setIsLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [filesToProcess, setFilesToProcess] = useState(0);
    const [filesProcessed, setFilesProcessed] = useState(0);
    const [error, setError] = useState<any>(null);
    const [CSVFile, setCSVFile] = useState<any>([]);
    const [apiHelper] = useState(ApiHelper());

    useEffect(() => {
        if (manualMappingUser) {
            setCrmUserId(manualMappingUser?.id)
            setCrmUsername(manualMappingUser?.username)
            if (manualMappingUser?.connectorId) {
                setConnectorId(manualMappingUser?.connectorId)
            }
            if(manualMappingRef && manualMappingRef.current) {
                //@ts-ignore
                manualMappingRef.current.scrollIntoView({ block: 'start',  behavior: 'smooth' });
            }
        }
    }, [manualMappingUser]);

    const clearError = () => {
        setError(null)
    }

    const readyForSubmit = () => {
        if (!connectorId || !userId || !crmUsername || Math.min(connectorId.length, userId.length, crmUsername.length) < 6) return false;
        if (!crmUserId || Math.min(crmUserId.length) < 1) return false;
        return (cloudcallIdValidator(connectorId) && cloudcallIdValidator(userId));
    }

    const uploadCSV = (event: any) => {
        setCSVFile([])
        const files = event.detail.value;
        if (files.length !== 1) return;
        setIsLoading(true);
        Papa.parse(files[0], {
            header: true,
            skipEmptyLines: true,
            complete: (results: any) => {
                bulkUpload(results.data)
            }
        });
    }

    const bulkUpload = async (results: any[]) => {
        let successfulCount = 0;
        let failedCount = 0;
        const total = results.length;
        setFilesToProcess(results.length)
        setFilesProcessed(0)
        setIsLoading(false);
        setIsProcessing(true);


        for (const mapping of results) {
            const index = results.indexOf(mapping);
            await apiHelper.makeSignedRequest('api/userMapping', "post", {
                clientConnectorId: mapping.clientConnectorId,
                crmUserId: mapping.crmUserId,
                crmUsername: mapping.crmUsername,
                userId: mapping.userId
            })
                .then(() => {
                    successfulCount++;
                })
                .catch((e) => {
                    console.error(e);
                    failedCount++;
                })

                    setFilesProcessed(index + 1)
        }
        setIsProcessing(false)
        let type = "success";
        let description = "All mappings were successful. Successfully created ";

        if (failedCount > 0) {
            if (successfulCount === 0) {
                type = "error"
                description = "No mappings were successful. Successfully created ";
            }
            else {
                type = "warning";
                description = "Some mappings were successful, some failed. Successfully created ";
            }
        }

        setError(<Alert
            statusIconAriaLabel="Info"
            dismissible
            onDismiss={clearError}
            //@ts-ignore
            type={type}
            header="Users mapping competed"
        >
            {`${description} ${successfulCount} of ${total}`}
        </Alert>)
    }

    const submitForm = () => {
        setIsLoading(true);
        clearError();
        apiHelper.makeSignedRequest('api/userMapping', "post", {
            clientConnectorId: connectorId,
            crmUserId,
            crmUsername,
            userId
        })
            .then((response) => {
                setError(<Alert
                    statusIconAriaLabel="Info"
                    dismissible
                    onDismiss={clearError}
                    type="success"
                    header="Complete"
                >
                    User mapping created
                </Alert>)
                setConnectorId("");
                setCrmUserId("")
                setCrmUsername("")
                setUserId("");
                setIsLoading(false)
                setManualMappingUser(null)
            })
            .catch((e) => {
                console.error(e)
                setError(<Alert
                    statusIconAriaLabel="Info"
                    dismissible
                    onDismiss={clearError}
                    type="error"
                    header="An error occurred"
                >
                    Please check the the entered values are correct, then try the request again
                </Alert>)
                setIsLoading(false)
            })
    }

    if (isLoading) {
        return  <Loading />
    }
    if (isProcessing) {
        return <Container header={
            <Header variant="h3">
                Manual Mapping
            </Header>
        }>
            <ProgressBar
                value={(filesProcessed/filesToProcess) * 100}
                additionalInfo={`Processing mapping ${filesProcessed + 1} of ${filesToProcess}`}
                description="Adding Mappings"
            />
        </Container>
    }
    return (
        <>
            <div ref={manualMappingRef}></div>
            <Container header={
                <Header variant="h3" description={<>
                    Create user mappings manually or in bulk by uploading a CSV based on <DomLink to={`${process.env.REACT_APP_REDIRECT_URL}files/sample.csv`} target="_blank" download>
                        this
                    </DomLink> sample
                </>}>
                    Manual Mapping
                </Header>
            }>
                <SpaceBetween size="m">
                    {error}
                    <FormField
                        description="CloudCall User ID"
                    >
                        <Input
                            value={userId}
                            onChange={event =>
                                setUserId(event.detail.value.trim())
                            }
                            invalid={!cloudcallIdValidator(userId)}
                        />
                    </FormField>
                    <FormField
                        description="Customer ID"
                    >
                        <CustomerIdSelector
                            initialValue={customerId}
                            onChange={setCustomerId}
                            invalid={!cloudcallIdValidator(customerId)}
                        />
                    </FormField>
                    <FormField
                        description="Client Connector Id"
                    >
                        <ConnectorSelector customerId={customerId} onChange={setConnectorId} initalValue={initialConnectorId} />
                    </FormField>
                    <FormField
                        description="CRM User ID"
                    >
                        <Input
                            value={crmUserId}
                            onChange={event =>
                                setCrmUserId(event.detail.value.trim())
                            }
                            invalid={!minLengthField(crmUserId, 1)}
                        />
                    </FormField>
                    <FormField
                        description="CRM Username"
                    >
                        <Input
                            value={crmUsername}
                            onChange={event =>
                                setCrmUsername(event.detail.value)
                            }
                            invalid={!minLengthField(crmUsername)}
                        />
                    </FormField>
                    <SpaceBetween size={"m"} direction={"horizontal"}>
                        <Button variant={"primary"} onClick={submitForm} disabled={!readyForSubmit()}>
                            Submit
                        </Button>
                        <FileUpload
                            onChange={uploadCSV}
                            value={CSVFile}
                            accept=".csv"
                            i18nStrings={{
                                uploadButtonText: e =>
                                    "Create from file",
                                dropzoneText: e =>
                                    e
                                        ? "Drop files to upload"
                                        : "Drop file to upload",
                                removeFileAriaLabel: e =>
                                    `Remove file ${e + 1}`,
                                limitShowFewer: "Show fewer files",
                                limitShowMore: "Show more files",
                                errorIconAriaLabel: "Error"
                            }}
                            tokenLimit={3}
                            constraintText="File must be a valid CSV"
                        />
                    </SpaceBetween>
                </SpaceBetween>
            </Container>
        </>
    )
}

export default ManualMapping;
