import {Alert} from "@cloudscape-design/components";

interface ErrorDisplayProps {
    clearError?: Function
}


const ErrorDisplay = ({clearError}:ErrorDisplayProps) => {
    const clearErrorHandler = () => {
        if (clearError && typeof clearError === "function") clearError();
    }
    return <Alert
        statusIconAriaLabel="Info"
        dismissible
        onDismiss={clearErrorHandler}
        type="error"
        header="An error occurred"
    >
        Please check the the entered values are correct, then try the request again
    </Alert>
}

export default ErrorDisplay;
