import {ContentLayout, Header, SpaceBetween} from "@cloudscape-design/components";
import {useParams} from "react-router-dom";
import React, {useContext, useState} from "react";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import {isRoleAdmin} from "../Helpers/role";
import TriggerContactSync from "../Components/TriggerContactSync";


const ContactSync = () => {
    let { connectorId, customerId } = useParams();

    const {tokenData} = useContext<IAuthContext>(AuthContext);
    const isAdmin = isRoleAdmin((tokenData || {}).role);
    return (
        <ContentLayout
            header={
                <Header variant="h1">
                    Contact Sync
                </Header>
            }
        >
            <SpaceBetween size={"l"}>
                <TriggerContactSync initialConnectorId={connectorId || null } initialCustomerId={customerId} />
            </SpaceBetween>
        </ContentLayout>
    );
}
export default ContactSync;
