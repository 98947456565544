import React, {useState} from "react";
import {
    Input, FormField, SpaceBetween, Button, Alert
} from "@cloudscape-design/components";
import {ApiHelper} from "../../Helpers/apiHelper";
import {cloudcallIdValidator} from "../../Helpers/validator";
import CustomerIdSelector from "../CustomerIdSelector";
import AuthConnectorProps from "./ConnectorTypes";

const connectorName = "Bullhorn";

const SetBullhornAuth = ({ setNotifications, clearNotifications, setIsLoading, connectorId }: AuthConnectorProps) => {

    const [apiHelper] = useState(ApiHelper())
    const [crmUsername, setCrmUsername] = useState("");
    const [crmPassword, setCrmPassword] = useState("");
    const [customerId, setCustomerId] = useState("");

    const getButtonIsDisabled = () => {
        let additionalBodyIsInvalid = !crmUsername || crmUsername.length === 0 || !crmPassword || crmPassword.length === 0
        return (additionalBodyIsInvalid || customerId.length === 0 || !cloudcallIdValidator(customerId));
    }

    const submitAuth = async () => {
        setIsLoading(true);
        clearNotifications();
        const existingConnectors = await apiHelper.makeSignedRequest(`api/clientConnectors?customerId=${customerId}`)
            .then((res) => {
                return res?.data.filter((connector: any) => {
                    return connector?.clientConfiguration?.integrationName === connectorName
                });
            })

        const isUpdate = existingConnectors.length > 0;
        let targetUrl = `api/connectors/v1/oauth/authenticate?connectorName=${connectorName}&customerId=${customerId}&connectorId=${connectorId}`;
        let customerMessage = `${connectorName} authentication credentials submitted`;
        if (isUpdate) {
            const targetIntegrationId = existingConnectors[0].id;
            targetUrl = `${targetUrl}&existingConnectionId=${targetIntegrationId}`
            customerMessage = `${connectorName} authentication credentials updated`;
        }
        apiHelper.makeSignedRequest(targetUrl, "POST", {
            username: crmUsername,
            password: crmPassword
        }, {
            "X-CC-CustomerId": customerId
        }).then((res) => {
            setNotifications(<Alert
                statusIconAriaLabel="Info"
                dismissible
                //@ts-ignore
                onDismiss={clearNotifications}
                //@ts-ignore
                type="success"
                header="Request Successful"
                key={"success"}
            >
                <SpaceBetween size={"xs"}>
                    <div key={"title"}>
                        {customerMessage}
                    </div>
                </SpaceBetween>
            </Alert>)
        })
            .catch((e) => {
                let message = "An error occurred fetching the url, please check the values entered or refresh and try again";
                let header = "Invalid Request"
                if (e.response?.status === 401) {
                    header = "Invalid username or password"
                    message = "The submitted credentials were rejected by Bullhorn, please check them and try again"
                }
                setNotifications(<Alert
                    statusIconAriaLabel="Error"
                    dismissible
                    //@ts-ignore
                    onDismiss={clearNotifications}
                    //@ts-ignore
                    type="error"
                    header={header}
                    key={"errorFetching"}
                >
                    {message}
                </Alert>)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }


    return <>
        <SpaceBetween size={"m"} >
            <FormField
                description="Customer ID"
            >
                <CustomerIdSelector
                    initialValue={customerId}
                    onChange={setCustomerId}
                    invalid={!cloudcallIdValidator(customerId)}
                />
            </FormField>
            <FormField
                description="CRM Username"
            >
                <Input value={crmUsername} onChange={({ detail }) => {
                    setCrmUsername(detail.value)
                }} />
            </FormField>
            <FormField
                description="CRM Password"
            >
                <Input value={crmPassword} type={"password"} onChange={({ detail }) => {
                    setCrmPassword(detail.value)
                }} />
            </FormField>
            <Button
                variant={"primary"}
                disabled={getButtonIsDisabled()}
                onClick={()=>submitAuth()}>
                Submit Authentication Credentials
            </Button>
        </SpaceBetween>
    </>

}

export default SetBullhornAuth;
