import {Alert, Box, Button, Cards, Container, Header, Link, SpaceBetween} from "@cloudscape-design/components";
import {useEffect, useState} from "react";
import { useAppSelector, useAppDispatch } from '../hooks'
import { fetchConnectors, getAllConnectors } from '../slices/availableConnectorsSlice'
import fetchStatus from "../types/fetchStatus";



const ListAllConnectors = () => {
    const dispatch = useAppDispatch()
    const availableConnectors = useAppSelector(getAllConnectors)
    const connectorStatus = useAppSelector(state => state.allConnectors.status)

    const header = (
        <Header variant="h3" description="These are the connectors that are currently available to customers">
            Available Connectors
        </Header>
    )
    useEffect(() => {
        if (connectorStatus === fetchStatus.idle) {
            dispatch(fetchConnectors())
        }
    }, [connectorStatus, dispatch])

    const dispachFetch = () => {
        dispatch(fetchConnectors())
    }

    if ( connectorStatus === fetchStatus.failed) {
        return (
            <Container header={
                header
            }>
                <Alert
                    statusIconAriaLabel="Info"
                    dismissible={false}
                    //@ts-ignore
                    type="error"
                    header="Invalid Request"
                    key={"errorFetching"}
                >
                    An error occurred fetching the available connectors -
                    <Button
                        formAction="none"
                        iconName="refresh"
                        variant="inline-icon"
                        onClick={dispachFetch}
                    >
                        Retry
                    </Button>
                </Alert>
            </Container>);
    }

    return (
        <Container header={ header }>
            <Cards
                cardDefinition={{
                    header: item => (
                        <Link href={`#/connectors/${item.id}`} fontSize="heading-m" onFollow={e => e.preventDefault()}>
                            {item.name}
                        </Link>
                    ),
                    sections: [
                        {
                            id: "id",
                            header: "id",
                            content: item => item.id
                        },
                        {
                            id: "version",
                            header: "Version",
                            content: item => item.version
                        }
                    ]
                }}
                cardsPerRow={[
                    { cards: 1 },
                    { minWidth: 500, cards: 2 }
                ]}
                entireCardClickable
                items={availableConnectors}
                loading={connectorStatus === fetchStatus.loading}
                loadingText="Loading resources"
                trackBy="name"
                visibleSections={["description", "id", "version"]}
                empty={
                    <Box
                        margin={{ vertical: "xs" }}
                        textAlign="center"
                        color="inherit"
                    >
                        <SpaceBetween size="m">
                            <b>No resources</b>
                        </SpaceBetween>
                    </Box>
                }
            />
        </Container>
    )
}

export default ListAllConnectors;
