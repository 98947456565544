import {
    Alert,
    Button,
    Container,
    ExpandableSection,
    FormField,
    Header,
    Input,
    SpaceBetween
} from "@cloudscape-design/components";
import React, {ReactElement, useContext, useEffect, useState} from "react";
import Loading from "./Loading";
import {ApiHelper} from "../Helpers/apiHelper";
import JsonView from "@uiw/react-json-view";
import {useNavigate} from "react-router-dom";
import {cloudcallIdValidator} from "../Helpers/validator";
import Confirm from "./Confirm";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import {isRoleAdmin} from "../Helpers/role";
import {useFlag} from "@unleash/proxy-client-react";
import CustomerIdSelector from "./CustomerIdSelector";
import {useAppDispatch, useAppSelector} from "../hooks";
import {fetchCustomerConnectors, getAllConnectors} from "../slices/customerConnectors";
import fetchStatus from "../types/fetchStatus";

const ListConnectorsByCustomerId = () => {

    const dispatch = useAppDispatch()


    const {tokenData} = useContext<IAuthContext>(AuthContext);
    const isAdmin = isRoleAdmin((tokenData || {}).role);

    const [isLoading, setIsLoading] = useState(false);
    const [customerId, setCustomerId] = useState("");
    const [targetCustomerId, setTargetCustomerId] = useState<string | undefined>(undefined);
    const [deleteConfirm, setDeleteConfirm] = useState<ReactElement | null>(null);
    // const [customerConnectors, setCustomerConnectors] = useState<any[]>([]);
    const allCustomerConnectors = useAppSelector(getAllConnectors)
    const [error, setError] = useState<any>(null);
    const [apiHelper] = useState(ApiHelper())
    const navigate = useNavigate();


    const contactsPageEnabled = useFlag('contacts.sync') && isRoleAdmin((tokenData || {}).role);

    const clearError = () => {
        setError([]);
    }

    const navigateToMapping = (connectorId: string) => {
        navigate(`/userMapping/connectorId/${connectorId}/customerId/${customerId}`)
    }
    const navigateToSync = (connectorId: string) => {
        navigate(`/contactSync/connectorId/${connectorId}/customerId/${customerId}`)
    }
    const showDeleteConfirm = (connectorId: string) => {
        setDeleteConfirm(<Confirm
            type="Integration"
            callback={deleteConfirmCallback}
            callbackProps={{connectorId: connectorId, customerId: customerId}}
        />)
    }
    const deleteConfirmCallback = (doDelete: boolean, callbackProps: any) => {
        setDeleteConfirm(null);
        if (!doDelete) return;
        setIsLoading(true);
        apiHelper.makeSignedRequest(`api/clientConnector/${callbackProps.connectorId}?customerId=${callbackProps.customerId}`, "DELETE")
            .then(() => {
                dispatch(fetchCustomerConnectors({customerId: callbackProps.customerId}))
            })
            .catch((e)=>{
                setIsLoading(false);
                setError(<Alert
                    statusIconAriaLabel="Error"
                    dismissible
                    onDismiss={clearError}
                    //@ts-ignore
                    type="error"
                    header="Could not delete the integration"
                >
                    An error occurred trying to delete this integration, please try again later
                </Alert>)
            })
    }
    const fetchDetails = () => {
        setTargetCustomerId(customerId);
        //@ts-ignore
        if (!allCustomerConnectors[customerId]) {
            dispatch(fetchCustomerConnectors({customerId: customerId}))
        }


    }
    const dispatchFetch = () => {
        dispatch(fetchCustomerConnectors({customerId: targetCustomerId as string}))
    }

    if (isLoading) {
        return  <Loading />
    }
    let connectorsDisplay = null;
    //@ts-ignore
    if (targetCustomerId && allCustomerConnectors[targetCustomerId]!== undefined) {
        //@ts-ignore
        if (allCustomerConnectors[targetCustomerId].state === fetchStatus.loading) {
            return <Loading />
        //@ts-ignore
        } else if (allCustomerConnectors[targetCustomerId].value.length === 0) {
            connectorsDisplay = (<Alert
                statusIconAriaLabel="Info"
                action={<Button variant="icon" onClick={dispatchFetch}  iconName="refresh" />}
                type="error"
                header="Could not fetch details"
            >
                An error occurred fetching the details for the entered customer ID, please check the details and try again
            </Alert>)
        } else {
            //@ts-ignore
            connectorsDisplay = allCustomerConnectors[targetCustomerId].value.map((connector) => {
                return (

                    <ExpandableSection key={connector.id}
                                       headerText={`ID: ${connector.id} - Connector: ${connector.clientConfiguration.integrationName || connector.integrationConnectorId}`}>
                        <SpaceBetween size={"s"}>
                            <SpaceBetween size={"s"} direction={"horizontal"}>
                                <Button variant="icon" onClick={dispatchFetch}  iconName="refresh" />
                                <Button onClick={() => {
                                    return navigateToMapping(connector.id)
                                }}>Map users with this integration</Button>
                                {contactsPageEnabled &&
                                  <Button onClick={() => {
                                      navigateToSync(connector.id)
                                  }}>Contact Sync</Button>
                                }
                                {isAdmin &&
                                  <Button variant={"primary"} onClick={() => {
                                      showDeleteConfirm(connector.id)
                                  }}>Delete</Button>
                                }
                            </SpaceBetween>
                            <JsonView value={connector.clientConfiguration}/>
                        </SpaceBetween>
                    </ExpandableSection>
                )
            })
        }
    }

    return (
        <Container
            fitHeight={true}
            header={
                <Header
                    variant="h3"
                    description={"List the Connectors that are currently configured for a given customer ID"}
                >
                    Customer Connectors
                </Header>
            }
        >
            <SpaceBetween size={"m"} >
                {error}
                <FormField
                    description="Customer ID"
                >
                    <CustomerIdSelector
                        initialValue={customerId}
                        onChange={setCustomerId}
                        invalid={!cloudcallIdValidator(customerId)}
                    />
                </FormField>
                <Button variant={"primary"} disabled={customerId.length === 0 || !cloudcallIdValidator(customerId)} onClick={fetchDetails}>Get Details</Button>
                {connectorsDisplay}
            </SpaceBetween>
            {deleteConfirm}
        </Container>
    )
}

export default ListConnectorsByCustomerId;
