import GetSalesforceAuthUrl from "./GetSalesforceAuthUrl";
import SetBullhornAuth from "./SetBullhornAuth";
import GetVincereAuthUrl from "./GetVincereAuthUrl";


const AvailableConnectors = {
    Salesforce: GetSalesforceAuthUrl,
    Bullhorn: SetBullhornAuth,
    Vincere: GetVincereAuthUrl
}

export default AvailableConnectors;
