import fetchStatus from "../types/fetchStatus";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import type {RootState} from "../store";
import ApiClient from "../Helpers/ApiClient";

interface CustomersState {
    connectors: {
    }
    status: string
    error: string | null
}
interface FetchConnectorsInput {
    customerId: string
}

const initialState: CustomersState = {
    connectors: {},
    status: fetchStatus.idle,
    error: null
}


export const fetchCustomerConnectors = createAsyncThunk('fetchCustomerConnectors', async (args: FetchConnectorsInput, { getState }) => {
    const state = getState() as RootState;
    if (!state.user.token) throw new Error('API called with no token');


    return await ApiClient.makeSignedRequest(state.user.token, `api/clientConnectors?customerId=${args.customerId}`);
})

export const customerConnectorsSlice = createSlice({
        name: 'customerConnectors',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder
            .addCase(fetchCustomerConnectors.pending, (state, action) => {

                const customerId = action.meta.arg.customerId;
                //@ts-ignore
                state.connectors[customerId] = {
                    state: fetchStatus.loading
                }
                state.status = fetchStatus.loading
            })
            .addCase(fetchCustomerConnectors.fulfilled, (state, action) => {
                state.status = fetchStatus.succeeded
                const customerId = action.meta.arg.customerId;
                //@ts-ignore
                state.connectors[customerId] = {
                    state: fetchStatus.succeeded,
                    value: action.payload
                };
            })
            .addCase(fetchCustomerConnectors.rejected, (state, action) => {
                state.status = fetchStatus.failed
                const customerId = action.meta.arg.customerId;
                //@ts-ignore
                state.connectors[customerId] = {
                    state: fetchStatus.failed
                }
                state.error = 'Failed to fetch connectors'
            })
    }
})

export const getConnectorForCustomer = (state: RootState, customerId: string) => {
    //@ts-ignore
    return state.customerConnectors.connectors[customerId];
}
export const getAllConnectors = (state: RootState) => state.customerConnectors.connectors

export default customerConnectorsSlice.reducer
