import {TAuthConfig} from "react-oauth2-code-pkce";

type _Config = {
    environmentName: string,
    apiBase: String,
    authConfig: TAuthConfig
}

const Config:_Config = {
    environmentName: process.env.REACT_APP_ENVIRONMENT || "dev",
    apiBase: process.env.REACT_APP_API_BASE || "",
    authConfig: {
        clientId: process.env.REACT_APP_CLIENT_ID || 'integrations-dashboard',
        authorizationEndpoint: `${process.env.REACT_APP_LOGIN_URL}/connect/authorize`,
        tokenEndpoint: `${process.env.REACT_APP_LOGIN_URL}/connect/token`,
        redirectUri: `${process.env.REACT_APP_REDIRECT_URL}`,
        scope: 'profile email openid permissions roles read write',
        logoutRedirect: `${process.env.REACT_APP_REDIRECT_URL}/logout`,
        extraTokenParameters: {
            audience: "aud:connectors"
        }

    }
}

export default Config;
