import React, {useEffect, useState} from 'react';

import {ApiHelper} from "../Helpers/apiHelper";

import Loading from "./Loading";
import {
    Alert,
    Button,
    Container, FormField,
    Header, Input, SpaceBetween
} from "@cloudscape-design/components";
import {Link as DomLink} from "react-router-dom";
import {cloudcallIdValidator} from "../Helpers/validator";
import UserMappingsTable from "./UserMappingsTable";
import CustomerIdSelector from "./CustomerIdSelector";
import ConnectorSelector from "./ConnectorSelector";

interface GetUserMappingsProps {
    initialConnectorId?: string | null,
    autoLoad?: boolean
}
function GetUserMappings({initialConnectorId, autoLoad}: GetUserMappingsProps) {

    const [connectorId, setConnectorId] = useState(initialConnectorId || "");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<any>(null);
    const [apiHelper] = useState(ApiHelper());
    const [foundMappings, setFoundMappings] = useState(null)
    const [lastConnectorId, setLastConnectorId] = useState("");
    const [customerId, setCustomerId] = useState( "");

    useEffect(() => {
        if (autoLoad && readyForSubmit()) getData();
    }, []);

    if (isLoading) {
        return  <Loading />
    }

    const clearError = () => {
        setError(null)
    }

    const readyForSubmit = () => {
        if (!connectorId || Math.min(connectorId.length) < 6) return false;
        return (cloudcallIdValidator(connectorId));
    }

    const refreshLast = async () => {
        setConnectorId(lastConnectorId);
        return submitForm();
    }

    const getData = async () => {
        return await apiHelper.makeSignedRequest(`api/userMapping?clientConnectorId=${connectorId}`, "GET")
            .then((res) => {
                setFoundMappings(res?.data);
                setLastConnectorId(connectorId);
                setIsLoading(false);
            })
            .catch((e) => {
                setIsLoading(false);
                setError(<Alert
                    statusIconAriaLabel="Error"
                    dismissible
                    onDismiss={clearError}
                    //@ts-ignore
                    type={"error"}
                    header="Error fetching mappings"
                >
                    {`Unable to fetch mappings, please check the connector ID and try again`}
                </Alert>)
            })
    }
    const submitForm = async () => {
        setIsLoading(true);
        clearError();
       return getData();
    }
    let foundMappingsDisplay = null;

    if (foundMappings !== null) { foundMappingsDisplay = <UserMappingsTable mappings={foundMappings} setLoading={setIsLoading} refreshItems={refreshLast} showError={setError} clearError={clearError}/> }



    return (
        <Container header={
            <Header variant="h3" description="Get all current mappings for a selected connector ID">
                All Current Mappings
            </Header>
        }>
            <SpaceBetween size="m">
                {error}
                <FormField
                    description="Customer ID"
                >
                    <CustomerIdSelector
                        initialValue={customerId}
                        onChange={setCustomerId}
                        invalid={!cloudcallIdValidator(customerId)}
                    />
                </FormField>
                <FormField
                    description="Client Connector Id"
                >
                    <ConnectorSelector customerId={customerId} onChange={setConnectorId} initalValue={initialConnectorId} />
                </FormField>
                <Button variant={"primary"} onClick={submitForm} disabled={!readyForSubmit()}>
                    Submit
                </Button>
                {foundMappingsDisplay}
            </SpaceBetween>
        </Container>
    );

}

export default GetUserMappings;
