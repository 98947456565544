import React, {useContext, useEffect, useState} from 'react';
import './App.css';
import {Route, Routes, useNavigate} from 'react-router-dom';
import { I18nProvider } from '@cloudscape-design/components/i18n';

import messages from '@cloudscape-design/components/i18n/messages/all.en';
import {
    AppLayout
} from '@cloudscape-design/components';
import NavigationMenu from "./Components/NavigationMenu";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import NoMatch from "./Routes/NoMatch";
import Loading from "./Components/Loading";
import Home from "./Routes/Home";
import UserMapping from "./Routes/UserMapping";
import {isRoleValid} from "./Helpers/role";
import { useUnleashContext, useFlag } from '@unleash/proxy-client-react';
import Config from "./Helpers/config";
import ContactSync from "./Routes/ContactSync";

import { useAppDispatch } from './hooks'
import { setUserToken } from './slices/userSlice'
import CustomerMappings from "./Routes/CustomerMappings";

const LOCALE = 'en';
let redirectTimoutHandler: NodeJS.Timeout;

function App() {
    const updateContext = useUnleashContext();
    const [navigateOpen, setNavigateOpen] = useState(true);
    const [loading, setLoading] = useState(true);
    const {token, tokenData, logOut} = useContext<IAuthContext>(AuthContext);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(()=> {
        redirectTimoutHandler = setTimeout(() => {
            if (!token) {
                navigate("/")
            }
        }, 2000);
        return () => {
            if (redirectTimoutHandler) {
                clearTimeout(redirectTimoutHandler)
            }
        }
    }, [navigate, token])

    useEffect(()=> {
        if (token && tokenData) {
            if (!isRoleValid(tokenData.role)) {
                logOut();
                navigate("/logout/403");
                return;
            }
            updateContext({
                userId: tokenData?.userId
            });
            dispatch(setUserToken(token));
            setLoading(false);
        }
        if (redirectTimoutHandler && token) {
            clearTimeout(redirectTimoutHandler)
        }
    }, [token, tokenData])

    if (loading) return ( <Loading />);

    return (
        <I18nProvider locale={LOCALE} messages={[messages]}>
          <AppLayout
              breadcrumbs={null}
              navigationOpen={navigateOpen}
              onNavigationChange={(event) => {
                  setNavigateOpen(event.detail.open)
              }}
              navigation={<NavigationMenu />}
              notifications={null }
              toolsHide={true}
              tools={null}
              content={
                  <Routes>
                      <Route index element={<Home />} />
                      <Route path="/connectors" element={<Home />} />
                      <Route path="/userMapping" element={<UserMapping />} />
                      <Route path="/userMapping/connectorId/:connectorId" element={<UserMapping />} />
                      <Route path="/userMapping/connectorId/:connectorId/customerId/:customerId" element={<UserMapping />} />
                      <Route path="/contactSync" element={<ContactSync />} />
                      <Route path="/contactSync/connectorId/:connectorId" element={<ContactSync />} />
                      <Route path="/contactSync/connectorId/:connectorId/customerId/:customerId" element={<ContactSync />} />
                      <Route path="/customerMappings" element={<CustomerMappings />} />
                      <Route path="*" element={<NoMatch />} />
                  </Routes>
              }
              splitPanel={null}
          />
      </I18nProvider>
    );
}

export default App;
