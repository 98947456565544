import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface CounterState {
    token: string | null
}

const initialState: CounterState = {
    token: null
}

export const userSlice = createSlice({
    name: 'user',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setUserToken: (state, action) => {
            state.token = String(action.payload)
        }
    },
})

export const { setUserToken } = userSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const getToken = (state: RootState) => state.user.token

export default userSlice.reducer
