import {
    Alert,
    Button,
    Container,
    FormField,
    Header,
    Select,
    SpaceBetween
} from "@cloudscape-design/components";
import {useEffect, useState} from "react";
import Loading from "./Loading";
import {OptionDefinition} from "@cloudscape-design/components/internal/components/option/interfaces";
import fetchStatus from "../types/fetchStatus";
import {fetchConnectors, getAllConnectors} from "../slices/availableConnectorsSlice";
import {useAppDispatch, useAppSelector} from "../hooks";
import AuthConnectors from "./CRMAuth"


const NoComponentError = ()=> {
    return (
        <Alert
            statusIconAriaLabel="Error"
            header="Not Configured"
            type="error"
        >
            The selected component either does not support manual auth, or the dashboard has not yet been configured to
            support it
        </Alert>
    )
}

const GetAuthUrl = () => {
    const [selectedConnector, setSelectedConnector] = useState<OptionDefinition | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [notifications, setNotifications] = useState<any>(null);
    const dispatch = useAppDispatch()
    const availableConnectors = useAppSelector(getAllConnectors)
    const connectorStatus = useAppSelector(state => state.allConnectors.status)
    const header = <Header variant="h3" description="Store Customer credentials or create an authoristation url to allow the customer to connect their CRM">
        Customer CRM Authentication
    </Header>

    useEffect(() => {
        if (connectorStatus === fetchStatus.idle) {
            dispatch(fetchConnectors())
        }
    }, [connectorStatus, dispatch])

    const dispachFetch = () => {
        dispatch(fetchConnectors())
    }

    if (isLoading || connectorStatus === fetchStatus.loading) {
        return <Loading />
    }

    if ( connectorStatus === fetchStatus.failed) {
        return (
            <Container header={
                header
            }>
                <Alert
                    statusIconAriaLabel="Error"
                    dismissible={false}
                    //@ts-ignore
                    type="error"
                    header="Invalid Request"
                    key={"errorFetching"}
                >
                    An error occurred fetching the available connectors -
                    <Button
                        formAction="none"
                        iconName="refresh"
                        variant="inline-icon"
                        onClick={dispachFetch}
                    >
                        Retry
                    </Button>
                </Alert>
            </Container>);
    }

    const clearNotifications = () => {
        setNotifications([]);
    }


    let ComponentToShow = null;

    if (selectedConnector) {
        //@ts-ignore
        const SelectedConnectorComponent = AuthConnectors[selectedConnector.label]
        if (SelectedConnectorComponent) {
            ComponentToShow = <SelectedConnectorComponent
                setNotifications={setNotifications}
                clearNotifications={clearNotifications}
                setIsLoading={setIsLoading}
                connectorId={selectedConnector.value}
            />
        } else {
            ComponentToShow = <NoComponentError />
        }

    }
    return (
        <Container header={
            header
        }>
            <SpaceBetween size={"m"} >
                {notifications}
                <FormField
                    description="Connector"
                >
                    <Select
                        selectedOption={selectedConnector}
                        onChange={({ detail }) =>
                            setSelectedConnector(detail.selectedOption)
                        }
                        options={availableConnectors.map((connector) => {
                            return { label: connector.name, value: connector.id }
                        })}
                    />
                </FormField>
                {ComponentToShow}
            </SpaceBetween>
        </Container>
    )
}

export default GetAuthUrl;
