import React, {ReactElement, useContext, useState} from "react";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import {isRoleAdmin} from "../Helpers/role";
import {useLocalStorage} from "usehooks-ts";
import {useCollection} from "@cloudscape-design/collection-hooks";
import {
    Box,
    Button,
    CollectionPreferences,
    Header,
    Pagination,
    SpaceBetween,
    TextFilter
} from "@cloudscape-design/components";
import Table from "@cloudscape-design/components/table";
import CrmUser from "../types/crmUser";

interface IntegrationsUserTableProps {
    users: any[],
    setLoading: Function
    showError: Function
    clearError: Function
    setManualMappingUser: Function
    connectorId: string
}


function IntegrationsUserTable({users, setManualMappingUser, connectorId}:IntegrationsUserTableProps) {
    const {tokenData} = useContext<IAuthContext>(AuthContext);
    const isAdmin = isRoleAdmin((tokenData || {}).role);
    const [
        selectedItems,
        setSelectedItems
    ] = React.useState<CrmUser[]>([]);
    const [preferences, setPreferences] = useLocalStorage('crm-users-preferences', { pageSize: 10, visibleContent: ['id', 'name', 'username', 'email'] });
    const pageSizePreference = {
        title: 'Select page size',
        options: [
            { value: 10, label: '10 users' },
            { value: 20, label: '20 users' },
            { value: 30, label: '30 users' },
            { value: 50, label: '50 users' },
        ],
    };
    const columnDefinitions = [
        {
            id: "id",
            header: "ID",
            cell: (item:CrmUser) => item.id,
            sortingField: "id",
        }
        ,{
            id: "name",
            header: "Name",
            cell: (item:CrmUser) => item.name,
            sortingField: "name",
        },{
            id: "username",
            header: "Username",
            cell: (item:CrmUser) => item.username,
            sortingField: "username",
        },{
            id: "email",
            header: "Email",
            cell: (item:CrmUser) => item.email,
            sortingField: "email",
        }
    ]

    const visibleContentPreference = {
        title: 'Select visible content',
        options: [
            {
                label: 'Main properties',
                options: columnDefinitions.map(({ id, header }) => ({ id, label: header, editable: true })),
            },
        ],
    };
    const collectionPreferencesProps = {
        pageSizePreference,
        visibleContentPreference,
        cancelLabel: 'Cancel',
        confirmLabel: 'Confirm',
        title: 'Preferences',
    };
    const getMatchesCountText = (count: number) => {
        return count === 1 ? `1 user` : `${count} users`;
    }
    const getUserCountForHeader = (total: number, filteredItemsCount?:number) => {
        return filteredItemsCount !== undefined && filteredItemsCount < total ? `(${filteredItemsCount}/${total})` : `(${filteredItemsCount})`;
    }

    const { items, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        users,
        {
            filtering: {
                empty: <Box
                    margin={{ vertical: "xs" }}
                    textAlign="center"
                    color="inherit"
                >
                    <SpaceBetween size="m">
                        <b>No Users found for this connector id & customer id</b>
                    </SpaceBetween>
                </Box>,
                noMatch: (
                    <Box
                        margin={{ vertical: "xs" }}
                        textAlign="center"
                        color="inherit"
                    >
                        <SpaceBetween size="m">
                            <b>No users found with this filter</b>
                        </SpaceBetween>
                    </Box>
                ),
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {},
            selection: {},
        }
    );


    const triggerManualMap = () => {
        if (selectedItems.length < 1) {
            return;
        }
        setManualMappingUser({
            ...selectedItems[0],
            connectorId
        })
    }
    return (
        <>
            <Table
                {...collectionProps}
                variant="embedded"
                header={
                    <Header
                        counter={getUserCountForHeader(users.length, filteredItemsCount)}
                        variant={"h3"}actions={
                        isAdmin &&
                          <Button variant="primary" disabled={selectedItems.length===0} onClick={triggerManualMap}>
                              Manually map selected user
                          </Button>
                        }
                    >
                        Found Users
                    </Header>
                }
                columnDefinitions={columnDefinitions}
                visibleColumns={preferences.visibleContent}
                pagination={<Pagination {...paginationProps} />}
                items={items}
                selectionType={isAdmin? "single" : undefined}
                selectedItems={selectedItems}
                onSelectionChange={({ detail }) =>
                    setSelectedItems(detail.selectedItems)
                }
                filter={
                    <TextFilter
                        {...filterProps}
                        countText={getMatchesCountText(filteredItemsCount || 0)}
                        filteringAriaLabel="Filter instances"
                    />
                }
                preferences={
                    <CollectionPreferences
                        {...collectionPreferencesProps}
                        preferences={preferences}
                        //@ts-ignore
                        onConfirm={({ detail }) => setPreferences(detail)}
                    />
                }
            />
        </>
    )
}
export default IntegrationsUserTable;
