import { configureStore } from '@reduxjs/toolkit'
import availableConnectorsReducer from '../src/slices/availableConnectorsSlice'
import userReducer from '../src/slices/userSlice'
import allCustomersReducer from  '../src/slices/customersSlice'
import customerConnectorsReducer from  '../src/slices/customerConnectors'

const store = configureStore({
  reducer: {
    allConnectors: availableConnectorsReducer,
    user: userReducer,
    allCustomers: allCustomersReducer,
    customerConnectors: customerConnectorsReducer
  },
})


export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;
