import React, {ReactElement, useContext, useState} from 'react';
import { useLocalStorage } from 'usehooks-ts'
import Table from "@cloudscape-design/components/table";
import {
    Alert,
    Box,
    Button,
    CollectionPreferences,
    Header,
    Pagination,
    SpaceBetween,
    TextFilter
} from "@cloudscape-design/components";
import { useCollection } from '@cloudscape-design/collection-hooks';
import {ApiHelper} from "../Helpers/apiHelper";
import Confirm from "./Confirm";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import {isRoleAdmin} from "../Helpers/role";

interface UserMappingsTableProps {
    mappings: any[],
    setLoading: Function
    refreshItems: Function
    showError: Function
    clearError: Function
}

interface UserMapping {
    userId: string,
    crmUsername?: string,
    crmUserId?: string,
    id: string,
    clientConnectorId: string
}



function UserMappingsTable({mappings, setLoading, refreshItems, showError, clearError}: UserMappingsTableProps) {
    const {tokenData} = useContext<IAuthContext>(AuthContext);
    const isAdmin = isRoleAdmin((tokenData || {}).role);
    const [preferences, setPreferences] = useLocalStorage('mappings-preferences', { pageSize: 10, visibleContent: ['userId', 'crmUsername'] });
    const [deleteConfirm, setDeleteConfirm] = useState<ReactElement | null>(null);
    const pageSizePreference = {
        title: 'Select page size',
        options: [
            { value: 10, label: '10 mappings' },
            { value: 20, label: '20 mappings' },
            { value: 30, label: '30 mappings' },
            { value: 50, label: '50 mappings' },
        ],
    };

    const [apiHelper] = useState(ApiHelper());
    const [
        selectedItems,
        setSelectedItems
    ] = React.useState<UserMapping[]>([]);
    const columnDefinitions = [
        {
            id: "userId",
            header: "CC User ID",
            cell: (item:UserMapping) => item.userId,
            sortingField: "userId",
        }
        ,{
            id: "crmUsername",
            header: "CRM Username",
            cell: (item:UserMapping) => item.crmUsername,
            sortingField: "crmUsername",
        },{
            id: "crmUserId",
            header: "CRM User ID",
            cell: (item:UserMapping) => item.crmUserId,
            sortingField: "crmUserId",
        },{
            id: "id",
            header: "CC Mapping ID",
            cell: (item:UserMapping) => item.id,
            sortingField: "id",
        },{
            id: "connectorId",
            header: "CC Connector ID",
            cell: (item:UserMapping) => item.clientConnectorId,
            sortingField: "clientConnectorId",
        }

    ]
    const visibleContentPreference = {
        title: 'Select visible content',
        options: [
            {
                label: 'Main properties',
                options: columnDefinitions.map(({ id, header }) => ({ id, label: header, editable: true })),
            },
        ],
    };
    const collectionPreferencesProps = {
        pageSizePreference,
        visibleContentPreference,
        cancelLabel: 'Cancel',
        confirmLabel: 'Confirm',
        title: 'Preferences',
    };
    const getMatchesCountText = (count: number) => {
        return count === 1 ? `1 mapping` : `${count} mappings`;
    }
    const getMappingCountForHeader = (total: number, filteredItemsCount?:number) => {
        return filteredItemsCount !== undefined && filteredItemsCount < total ? `(${filteredItemsCount}/${total})` : `(${filteredItemsCount})`;
    }

    const deleteSelectedMappings = async () => {
        if (selectedItems.length === 0) return console.warn("Delete called with no mappings selected");
        showDeleteConfirm();
    }
    const showDeleteConfirm = () => {
        setDeleteConfirm(<Confirm
            type={selectedItems.length > 1 ? "User Mappings" : "User Mapping"}
            callback={deleteConfirmCallback}
            plural={selectedItems.length > 1}
        />)
    }
    const deleteConfirmCallback = (doDelete: boolean, callbackProps: any) => {
        setDeleteConfirm(null);
        if (!doDelete) return;
        setLoading(true);
        const requests = selectedItems.map(async (item) => {
            return await apiHelper.makeSignedRequest(`api/userMapping/${item.id}`, "DELETE")
        })
        Promise.allSettled(requests).then((res) => {
            const failedRequests = res.filter((response) => {
                return response.status === "rejected";
            })

            if (failedRequests.length === res.length) {
                showError(<Alert
                    statusIconAriaLabel="Error"
                    dismissible
                    //@ts-ignore
                    onDismiss={clearError}
                    //@ts-ignore
                    type={"error"}
                    header="Mappings deletion failed"
                >
                    {`Unable to delete mappings, none were deleted`}
                </Alert>)
                setLoading(false);
                return;
            }

            setSelectedItems([]);
            refreshItems();

            if (failedRequests.length === 0) {
                showError(<Alert
                    statusIconAriaLabel="Warning"
                    dismissible
                    //@ts-ignore
                    onDismiss={clearError}
                    //@ts-ignore
                    type={"success"}
                    header="Mapping deletion success"
                >
                    {`Selected mappings were deleted`}
                </Alert>)
                return;
            }
            showError(<Alert
                statusIconAriaLabel="Warning"
                dismissible
                //@ts-ignore
                onDismiss={clearError}
                //@ts-ignore
                type={"warning"}
                header="Some mappings deletion failed"
            >
                {`Some mappings were deleted but ${failedRequests.length} were unable to be removed`}
            </Alert>)
        })
    }
    const { items, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        mappings,
        {
            filtering: {
                empty: <Box
                    margin={{ vertical: "xs" }}
                    textAlign="center"
                    color="inherit"
                >
                    <SpaceBetween size="m">
                        <b>No Mappings Found for this connector ID</b>
                    </SpaceBetween>
                </Box>,
                noMatch: (
                    <Box
                        margin={{ vertical: "xs" }}
                        textAlign="center"
                        color="inherit"
                    >
                        <SpaceBetween size="m">
                            <b>No Mappings Found with this filter</b>
                        </SpaceBetween>
                    </Box>
                ),
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {},
            selection: {},
        }
    );

    return (
        <>
            <Table
                {...collectionProps}
                variant="embedded"
                header={
                    <Header
                        counter={getMappingCountForHeader(mappings.length, filteredItemsCount)}
                        variant={"h3"}
                        actions={
                            isAdmin &&
                                <Button variant="primary" disabled={selectedItems.length===0} onClick={deleteSelectedMappings}>
                                    { selectedItems.length > 1 ? `Delete ${selectedItems.length} selected mappings` : `Delete selected mapping`}
                                </Button>
                        }
                    >
                        Found Mappings
                    </Header>
                }
                columnDefinitions={columnDefinitions}
                visibleColumns={preferences.visibleContent}
                pagination={<Pagination {...paginationProps} />}
                items={items}
                onSelectionChange={({ detail }) =>
                    setSelectedItems(detail.selectedItems)
                }
                selectedItems={selectedItems}
                selectionType={isAdmin? "multi" : undefined}
                filter={
                    <TextFilter
                        {...filterProps}
                        countText={getMatchesCountText(filteredItemsCount || 0)}
                        filteringAriaLabel="Filter instances"
                    />
                }
                preferences={
                    <CollectionPreferences
                        {...collectionPreferencesProps}
                        preferences={preferences}
                        //@ts-ignore
                        onConfirm={({ detail }) => setPreferences(detail)}
                    />
                }
            />
            {deleteConfirm}
        </>
    );
}

export default UserMappingsTable;
