import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createHashRouter,
    RouterProvider,
} from "react-router-dom";
import "@cloudscape-design/global-styles/index.css"
import { AuthProvider, TRefreshTokenExpiredEvent} from "react-oauth2-code-pkce"
import store from './store'
import { Provider } from 'react-redux'


import App from './App';
import Logout from "./Routes/Logout";
import Config from "./Helpers/config";
const authConfig = {
    ...Config.authConfig,
    onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) => window.confirm('Session expired. Refresh page to continue using the site?') && event.login()
}
import { FlagProvider } from '@unleash/proxy-client-react';
import UnleashConfig from './types/unleash';

const router = createHashRouter([
    {
        path: "/logout/:reason",
        element: (<Logout />)
    },{
        path: "/logout",
        element: (<Logout />)
    }, {
        path: "*",
        element: (<AuthProvider authConfig={authConfig}><App /></AuthProvider>)
    }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const unleashConfig:UnleashConfig = {
    url: 'https://eu.app.unleash-hosted.com/dodger/api/frontend', // Your front-end API URL or the Unleash proxy's URL (https://<proxy-url>/proxy)
    clientKey: process.env.REACT_APP_UNLEASH_ID || '', // A client-side API token OR one of your proxy's designated client keys (previously known as proxy secrets)
    refreshInterval: 300, // How often (in seconds) the client should poll the proxy for updates
    appName: 'Integrations Dashboard Frontend', // The name of your application. It's only used for identifying your application
};

root.render(
  <React.StrictMode>
      <Provider store={store}>
          <FlagProvider config={unleashConfig}>
              <RouterProvider router={router} />
          </FlagProvider>
      </Provider>
  </React.StrictMode>
);
