import * as React from "react";
import SideNavigation from "@cloudscape-design/components/side-navigation";
import {useContext, useEffect} from "react";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import {useNavigate} from "react-router-dom";
const defaultBasePath = "/connectors"
import { useFlag } from '@unleash/proxy-client-react';
import {isRoleAdmin} from "../Helpers/role";
import {Button} from "@cloudscape-design/components";


interface EnvironmentLink {
    type: string,
    text: string,
    href: string
    env?: string
    external: boolean
}
const NavigationMenu = () => {

    const navigate = useNavigate();
    const {tokenData} = useContext<IAuthContext>(AuthContext);
    const isAdmin = isRoleAdmin((tokenData || {}).role);
    const contactsPageEnabled = useFlag('contacts.sync') && isAdmin;
    let currentBasePath = window.location.hash;

    const [activeHref, setActiveHref] = React.useState(
        defaultBasePath
    );


    useEffect(()=> {
        if (currentBasePath.length === 0) {
            setActiveHref(defaultBasePath);
        } else {
            setActiveHref(`/${currentBasePath.replace('#', '').split("/")[1]}`);
        }
    }, [currentBasePath])

    const {logOut} = useContext<IAuthContext>(AuthContext);
    const otherEnvironmentLinks: EnvironmentLink[] = [
        {
            type: "link",
            text: "Production",
            href: "https://dashboard.integrations.starbug.ninja/",
            env: "prod",
            external: true
        },{
            type: "link",
            text: "Staging",
            href: "https://dashboard.integrations.staging.starbug.ninja/",
            env: "staging",
            external: true
        },{
            type: "link",
            text: "Test",
            href: "https://dashboard.integrations.test.starbug.ninja/",
            env: "test",
            external: true
        },
    ]
        .filter((item:EnvironmentLink) => item?.env !== process.env.REACT_APP_ENVIRONMENT)
        .map((item:EnvironmentLink) => {
            delete item.env
            return item
        })
    const mainItems = [{ type: "link", text: "Connectors", href: "/#/connectors" },
        { type: "link", text: "User Mapping", href: "/#/userMapping" }]
    if (contactsPageEnabled) {
        mainItems.push({ type: "link", text: "Contact Sync", href: "/#/contactSync" })
    }
    if (isAdmin) {
        mainItems.push({ type: "link", text: "Customer Mappings", href: "/#/customerMappings" })
    }

    const menuItems = [
        ...mainItems,
        { type: "divider" },

        {
            type: "section",
            text: "Other Environments",

            //@ts-ignore
            items: otherEnvironmentLinks
        },
        { type: "divider" },
        { type: "link", text: "Logout", href: "/logout"  },
    ]
    return (
        <SideNavigation
            activeHref={activeHref}
            header={{ href: "/#/", text: "Integrations Dashboard" }}
            onFollow={event => {
                if (event.detail.href === "/logout") {
                    event.preventDefault();
                    logOut();
                }
                if (!event.detail.external) {
                    const targetUrl = event.detail.href.replace('/#', '')
                    event.preventDefault();
                    setActiveHref(targetUrl);
                    navigate(targetUrl)
                }

            }}
            //@ts-ignore
            items={menuItems}
        />
    );
}

export default NavigationMenu;
