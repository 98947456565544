import {
    Box,
    Button, CollectionPreferences,
    Container,
    ContentLayout, FormField,
    Header, Input,
    Pagination,
    SpaceBetween,
    TextFilter
} from "@cloudscape-design/components";
import {useAppDispatch, useAppSelector} from "../hooks";
import {fetchCustomers, getAllCustomers} from "../slices/customersSlice";
import React, {useEffect, useState} from "react";
import fetchStatus from "../types/fetchStatus";
import {useLocalStorage} from "usehooks-ts";
import {useCollection} from "@cloudscape-design/collection-hooks";
import Table from "@cloudscape-design/components/table";

import {ApiHelper} from "../Helpers/apiHelper";
import {cloudcallIdValidator} from "../Helpers/validator";

interface CustomerDetails {
    id: string,
    name: string,
}

const CustomerMappings = () => {
    const dispatch = useAppDispatch()
    const availableCustomers = useAppSelector(getAllCustomers)

    const [
        selectedItems,
        setSelectedItems
    ] = React.useState<CustomerDetails[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [customerName, setCustomerName] = useState('');
    const [customerId, setCustomerId] = useState('');
    const customerStatus = useAppSelector(state => state.allCustomers.status)



    const [apiHelper] = useState(ApiHelper());

    useEffect(() => {
        if (customerStatus === fetchStatus.idle) {
            dispatch(fetchCustomers())
        }
    }, [customerStatus, dispatch])

    const dispatchFetch = () => {
        dispatch(fetchCustomers())
    }
    const [preferences, setPreferences] = useLocalStorage('customer-list-preferences', { pageSize: 10, visibleContent: ['id', 'name'] });
    const pageSizePreference = {
        title: 'Select page size',
        options: [
            { value: 10, label: '10 customers' },
            { value: 20, label: '20 customers' },
            { value: 30, label: '30 customers' },
            { value: 50, label: '50 customers' },
        ],
    };
    const columnDefinitions = [
        {
            id: "id",
            header: "ID",
            cell: (item:CustomerDetails) => item.id,
            sortingField: "id",
        }
        ,{
            id: "name",
            header: "Name",
            cell: (item:CustomerDetails) => item.name,
            sortingField: "name",
        }
    ];

    const visibleContentPreference = {
        title: 'Select visible content',
        options: [
            {
                label: 'Main properties',
                options: columnDefinitions.map(({ id, header }) => ({ id, label: header, editable: true })),
            },
        ],
    };
    const collectionPreferencesProps = {
        pageSizePreference,
        visibleContentPreference,
        cancelLabel: 'Cancel',
        confirmLabel: 'Confirm',
        title: 'Preferences',
    };
    const getMatchesCountText = (count: number) => {
        return count === 1 ? `1 user` : `${count} customer`;
    }
    const getUserCountForHeader = (total: number, filteredItemsCount?:number) => {
        return filteredItemsCount !== undefined && filteredItemsCount < total ? `(${filteredItemsCount}/${total})` : `(${filteredItemsCount})`;
    }

    const { items, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        availableCustomers,
        {
            filtering: {
                empty: <Box
                    margin={{ vertical: "xs" }}
                    textAlign="center"
                    color="inherit"
                >
                    <SpaceBetween size="m">
                        <b>No customers found</b>
                    </SpaceBetween>
                </Box>,
                noMatch: (
                    <Box
                        margin={{ vertical: "xs" }}
                        textAlign="center"
                        color="inherit"
                    >
                        <SpaceBetween size="m">
                            <b>No customers found with this filter</b>
                        </SpaceBetween>
                    </Box>
                ),
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {},
            selection: {},
        }
    );

    const deleteCustomer = () => {
        setIsLoading(true)
        const targetId = selectedItems[0].id;
        apiHelper.makeSignedRequest('api/customerMappings',"DELETE", {
            id: targetId
        }).then(() => {
            dispatchFetch();
            setIsLoading(false);
        }).catch((e) => {
            setIsLoading(false);
            alert("An error occurred deleting this customer mapping");
        })
    }

    const readyForSubmit = () => {
        return customerName.length > 3 && customerId.length > 0 && cloudcallIdValidator(customerId) && !isLoading
    }
    const submitForm = () => {
        setIsLoading(true)
        const body = {
            id: customerId,
            name: customerName
        }

        apiHelper.makeSignedRequest('api/customerMappings',"POST", body)
            .then(() => {
                dispatchFetch();
                setIsLoading(false);
                setCustomerId('');
                setCustomerName('');
            }).catch((e) => {
                setIsLoading(false);
                alert("An error occurred creating this customer mapping");
            })
    }

    return (
        <ContentLayout
            header={
                <Header variant="h1" description={"Manage selectable customers in the console, this will not effect production customer config"}>
                    Customer Mapping
                </Header>
            }
        >
            <SpaceBetween size={"l"}>
                <Table
                    {...collectionProps}
                    variant="container"
                    header={
                        <Header
                            counter={getUserCountForHeader(availableCustomers.length, filteredItemsCount)}
                            variant={"h3"}
                            actions={
                                <>
                                    <Button variant="icon" onClick={deleteCustomer}  iconName="remove" disabled={selectedItems.length === 0} />
                                    <Button variant="icon" onClick={dispatchFetch}  iconName="refresh" />
                                </>
                            }
                        >
                            Found Customers
                        </Header>
                    }
                    columnDefinitions={columnDefinitions}
                    visibleColumns={preferences.visibleContent}
                    pagination={<Pagination {...paginationProps} />}
                    items={items}
                    selectionType={"single"}
                    selectedItems={selectedItems}
                    onSelectionChange={({ detail }) =>
                        setSelectedItems(detail.selectedItems)
                    }
                    filter={
                        <TextFilter
                            {...filterProps}
                            countText={getMatchesCountText(filteredItemsCount || 0)}
                            filteringAriaLabel="Filter instances"
                        />
                    }
                    loading={customerStatus===fetchStatus.loading || isLoading}
                    preferences={
                        <CollectionPreferences
                            {...collectionPreferencesProps}
                            preferences={preferences}
                            //@ts-ignore
                            onConfirm={({ detail }) => setPreferences(detail)}
                        />
                    }
                />

                <Container header={
                    <Header variant="h3">
                        Creat/Update Customer Mapping
                    </Header>
                }>
                    <SpaceBetween size="m">
                        {/*{error}*/}
                        <FormField
                            description="CloudCall Customer ID"
                        >
                            <Input
                                value={customerId}
                                onChange={event =>
                                    setCustomerId(event.detail.value)
                                }
                                invalid={!cloudcallIdValidator(customerId)}
                            />
                        </FormField>
                        <FormField
                            description="Customer Name"
                        >
                            <Input
                                value={customerName}
                                onChange={event =>
                                    setCustomerName(event.detail.value)
                                }
                            />
                        </FormField>
                        <Button variant={"primary"} onClick={submitForm} disabled={!readyForSubmit()}>
                            Submit
                        </Button>
                    </SpaceBetween>
                </Container>
            </SpaceBetween>
        </ContentLayout>
    );
}
export default CustomerMappings;
