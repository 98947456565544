import {useAppDispatch, useAppSelector} from "../hooks";
import {fetchCustomers, getAllCustomers, setLastUsed, getLastUsedCustomer } from "../slices/customersSlice";
import {useEffect, useState} from "react";
import fetchStatus from "../types/fetchStatus";
import {Autosuggest } from "@cloudscape-design/components";

interface CustomerIdSelectorProps {
    onChange?: Function | null,
    initialValue?: string,
    invalid? :boolean
}
const CustomerIdSelector = ( {onChange, invalid, initialValue} : CustomerIdSelectorProps) => {

    const dispatch = useAppDispatch()
    const availableCustomers = useAppSelector(getAllCustomers)
    const lastUsedCustomer = useAppSelector(getLastUsedCustomer)
    const customerStatus = useAppSelector(state => state.allCustomers.status)

    useEffect(() => {
        if (customerStatus === fetchStatus.idle) {
            dispatch(fetchCustomers())
        }
    }, [customerStatus, dispatch])

    let targetValue = initialValue;
    if (!initialValue || initialValue.length === 0) {
        targetValue = lastUsedCustomer || "";
    }

    const [value, setValue] = useState(targetValue || "");
    const valueChanged =(value: string) => {
        if (onChange && typeof onChange === "function") {
            onChange(value);
        }
        setValue(value)
    }
    const onSelect = (value: string) => {
        setLastUsed(value)
    }
    const getStatusType = () => {
        if (customerStatus === fetchStatus.loading) return 'loading';
        if (customerStatus === fetchStatus.failed) return 'error';
        return 'finished';

    }
    return (
        <Autosuggest
            onChange={({ detail }) => valueChanged(detail.value)}
            onSelect={({ detail }) => onSelect(detail.value)}
            value={value}
            options={(availableCustomers || []).map((customer) => {
                return {
                    value: customer.id,
                    label: customer.name
                }
            })}
            invalid={invalid}
            ariaLabel="Autosuggest example with values and labels"
            placeholder="Enter Name to search, or a customer ID"
            empty="No matches found"
            expandToViewport
            loadingText="Loading Customers"
            statusType={getStatusType()}
        />
    );
}


export default CustomerIdSelector;
