import React, {useEffect, useState} from 'react';
import {Button, Box, Input, Modal, SpaceBetween, InputProps} from "@cloudscape-design/components";

interface ConfirmationBoxProps {
    callback?: Function,
    callbackProps?: any,
    plural?: boolean,
    type: string
}

const getRandom = () => {
    return Math.floor(Math.random() * 10) + 1;
}
function ConfirmationBox({ callback, callbackProps, type, plural }: ConfirmationBoxProps) {
    const [isValid, setIsValid] = useState(false);
    const [number1, setNumber1] = useState<null | number>(null);
    const [number2, setNumber2] = useState<null | number>(null);
    const [answer, setAnswer] = useState<null | number>(null);

    useEffect(() => {
        const numbers = [
            getRandom(),
            getRandom()
        ]
        setNumber1(numbers[0])
        setNumber2(numbers[1])
        setAnswer(numbers[0]+numbers[1]);
    }, []);


    const [userAnswer, setUserAnswer] = useState("");

    const callbackWrapper = (confirm = false) =>{
        if(callback) {
            callback(confirm, callbackProps);
        }
    }
    const answerChanged = (event: any) => {
        const value = event.detail.value
        const parsedValue = parseInt(value)
        if (value.length > 0 && (!parsedValue || `${parsedValue}` !== value )) return;
        setUserAnswer(`${value}`);
        setIsValid(parsedValue === answer);
    };

    return (
        <Modal
            visible={answer!==null}
            onDismiss={()=>{callbackWrapper(false)}}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={()=>{callbackWrapper(false)}}>Cancel</Button>
                        <Button variant="primary" onClick={()=>{callbackWrapper(true)}} disabled={!isValid}>Ok</Button>
                    </SpaceBetween>
                </Box>
            }
            header={plural ? `Delete these ${type}?` : `Delete this ${type}?`}
        >
            <SpaceBetween size={"s"}>
                <Box variant="p">
                    {plural ?`Are you sure you want to delete these ${type}?` : `Are you sure you want to delete this ${type}?` }
                </Box>
                <Box variant="p">
                    {`To confirm the delete, please enter the answer of ${number1} + ${number2}`}
                </Box>
                <Input
                    onChange={answerChanged}
                    value={userAnswer}
                    inputMode="numeric"
                />
            </SpaceBetween>
        </Modal>
    );
}

export default ConfirmationBox;
