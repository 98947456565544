import {Alert, Button, Container, FormField, Header, Input, SpaceBetween, Toggle} from "@cloudscape-design/components";
import {useState} from "react";
import Loading from "./Loading";
import {ApiHelper} from "../Helpers/apiHelper";
import {cloudcallIdValidator} from "../Helpers/validator";
import ErrorDisplay from "./ErrorDisplay";
import CustomerIdSelector from "./CustomerIdSelector";
import ConnectorSelector from "./ConnectorSelector";


interface AutoMappingProps {
    initialConnectorId?: string | null,
    initialCustomerId?: string | null,
}


const Automap = ({initialConnectorId, initialCustomerId}: AutoMappingProps) => {
    const [connectorId, setConnectorId] = useState(initialConnectorId || "");
    const [customerId, setCustomerId] = useState(initialCustomerId || "");
    const [exactMatch, setExactMatch] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<any>(null);
    const [apiHelper] = useState(ApiHelper())

    const clearError = () => {
        setError(null)
    }

    const readyForSubmit = () => {
        console.log(customerId, connectorId)
        if (!customerId || !connectorId || Math.min(customerId?.length,connectorId?.length) === 0) return false;
        return (cloudcallIdValidator(customerId) && cloudcallIdValidator(connectorId));
    }

    const submitForm = () => {
        setIsLoading(true);
        clearError();
        try {
            apiHelper.makeSignedRequest(`api/userMapping/automap?customerId=${customerId}`, "post", {
                clientConnectorId: connectorId,
                exactMatch: exactMatch
            })
                .then(() => {
                    setError(<Alert
                        statusIconAriaLabel="Info"
                        dismissible
                        onDismiss={clearError}
                        type="success"
                        header="Complete"
                    >
                        User auto mapping was submitted
                    </Alert>)
                    setIsLoading(false)
                })
                .catch((e) => {
                    setError(<ErrorDisplay clearError={clearError} /> )
                    setIsLoading(false)
                })
        }
        catch (e) {
            console.log(e)
        }
    }

    if (isLoading) {
        return  <Loading />
    }

    return (
        <Container header={
            <Header variant="h3" description={"Automapping will attempt to resolve variations on email addresses by ignoring anything after the + in an address"}>
                Auto Mapping
            </Header>
        }>
            <SpaceBetween size="m">
                {error}
                <FormField
                    description="Customer ID"
                >
                    <CustomerIdSelector
                        initialValue={customerId}
                        onChange={setCustomerId}
                        invalid={!cloudcallIdValidator(customerId)}
                    />
                </FormField>
                <FormField
                    description="Client Connector Id"
                >
                    <ConnectorSelector customerId={customerId} onChange={setConnectorId} />
                </FormField>
                <FormField
                    description="Exact Match?"
                >
                    <Toggle
                        onChange={({ detail }) =>
                            setExactMatch(detail.checked)
                        }
                        checked={exactMatch}
                    >
                        Toggle
                    </Toggle>
                </FormField>
                <Button variant={"primary"} onClick={submitForm} disabled={!readyForSubmit()}>
                    Submit
                </Button>
            </SpaceBetween>
        </Container>
    );
}
export default Automap;
