import {ContentLayout, Header, SpaceBetween} from "@cloudscape-design/components";
import Automap from "../Components/Automap";
import ManualMapping from "../Components/ManualMapping";
import {useParams} from "react-router-dom";
import GetUserMappings from "../Components/GetUserMappings";
import {useContext, useState} from "react";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import {isRoleAdmin} from "../Helpers/role";
import GetIntegrationUsers from "../Components/GetIntegrationUsers";


const UserMapping = () => {
    let { connectorId, customerId } = useParams();

    const {tokenData} = useContext<IAuthContext>(AuthContext);
    const isAdmin = isRoleAdmin((tokenData || {}).role);
    const [manualMappingUser, setManualMappingUser] = useState(null)
    return (
        <ContentLayout
            header={
                <Header variant="h1">
                    User Mapping
                </Header>
            }
        >
            <SpaceBetween size={"l"}>
                { isAdmin &&
                  <Automap initialConnectorId={connectorId || null} initialCustomerId={customerId || null} />
                }
                { isAdmin &&
                    <ManualMapping initialConnectorId={connectorId || null } manualMappingUser={manualMappingUser} setManualMappingUser={setManualMappingUser} />
                }
                <GetUserMappings initialConnectorId={connectorId || null }  autoLoad={!isAdmin} />
                <GetIntegrationUsers initialConnectorId={connectorId || null } initialCustomerId={customerId} setManualMappingUser={setManualMappingUser} />
            </SpaceBetween>
        </ContentLayout>
    );
}
export default UserMapping;
