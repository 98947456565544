import {Container, Header, Spinner} from "@cloudscape-design/components";

const Loading = () => {
    return (
        <Container
            fitHeight={true}
            header={
                <Header
                    variant="h1"
                >
                    Loading
                </Header>
            }
        >
            <Spinner size={"large"} />
        </Container>
    )
}

export default Loading;
