import {Alert, Button, Container, FormField, Header, Input, SpaceBetween} from "@cloudscape-design/components";
import {cloudcallIdValidator} from "../Helpers/validator";
import React, {useState} from "react";
import {ApiHelper} from "../Helpers/apiHelper";
import Loading from "./Loading";
import IntegrationsUserTable from "./IntegrationsUserTable";
import CustomerIdSelector from "./CustomerIdSelector";
import ConnectorSelector from "./ConnectorSelector";

interface GetIntegrationUsersProps {
    initialConnectorId?: string | null,
    initialCustomerId?: string | null
    setManualMappingUser: Function
}


function GetIntegrationUsers({initialCustomerId, initialConnectorId, setManualMappingUser}: GetIntegrationUsersProps) {
    const [connectorId, setConnectorId] = useState(initialConnectorId || "");
    const [customerId, setCustomerId] = useState(initialCustomerId || "");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<any>(null);
    const [apiHelper] = useState(ApiHelper());
    const [foundUsers, setFoundUsers] = useState(null)
    const getData = async () => {

        return await apiHelper.makeSignedRequest(`api/clientConnector/${connectorId}/users?customerId=${customerId}`, "GET")
            .then((res) => {
                setFoundUsers(res?.data);
                setIsLoading(false);
            })
            .catch((e) => {
                setIsLoading(false);
                setFoundUsers(null)
                setError(<Alert
                    statusIconAriaLabel="Error"
                    dismissible
                    onDismiss={clearError}
                    //@ts-ignore
                    type={"error"}
                    header="Error fetching users"
                >
                    {`Unable to fetch mappings, please check the customer ID & connector ID and try again`}
                </Alert>)
            })
    }

    const readyForSubmit = () => {
        if (!connectorId || Math.min(connectorId.length) < 6) return false;
        return (cloudcallIdValidator(connectorId) && cloudcallIdValidator(customerId));
    }

    const clearError = () => {
        setError(null)
    }

    const submitForm = async () => {
        setIsLoading(true);
        clearError();
        return getData();
    }
    if (isLoading) {
        return  <Loading />
    }


    let foundUsersDisplay = null;
    if (foundUsers !== null) { foundUsersDisplay = <IntegrationsUserTable connectorId={connectorId} users={foundUsers} setLoading={setIsLoading} showError={setError} clearError={clearError} setManualMappingUser={setManualMappingUser}/> }

    return (
        <Container header={
            <Header variant="h3" description="Get all current CRM users from the Customer CRM for the selected integration">
                Get all CRM users
            </Header>
        }>
            <SpaceBetween size="m">
                {error}
                <FormField
                    description="Customer ID"
                >
                    <CustomerIdSelector
                        initialValue={customerId}
                        onChange={setCustomerId}
                        invalid={!cloudcallIdValidator(customerId)}
                    />
                </FormField>
                <FormField
                    description="Client Connector ID"
                >
                    <ConnectorSelector customerId={customerId} onChange={setConnectorId} />
                </FormField>
                <Button variant={"primary"} onClick={submitForm} disabled={!readyForSubmit()}>
                    Submit
                </Button>
                {foundUsersDisplay}
            </SpaceBetween>
        </Container>
    )
}




export default GetIntegrationUsers;
