import axios from "axios";
import Config from "./config";
const baseUrl = Config.apiBase;

const ApiClient = {
    makeSignedRequest: async (token: string, path: string, method: string = "get", body?:any, headers?:object) => {
        let headersToPass = {
            'authorization': token
        }
        if (headers) {
            headersToPass = {
                ...headersToPass,
                ...headers
            }
        }
        try {
            return axios({
                method,
                url: `${baseUrl}/${path}`,
                headers: headersToPass,
                data: body
            }).then((res) => {
                return res.data;
            });
        } catch (e) {
            console.error(e)
        }
    }
}

export default ApiClient
