const MIN_LENGTH = 6;

const cloudcallIdValidator = (input: string) => {
    if (!input || input.length === 0) return true;
    const uuidPattern: RegExp = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return uuidPattern.test(input);
}
const minLengthField = (input: string, min: number = MIN_LENGTH) => {
    return (input.length === 0 || input.length >= min);

}


export {
    cloudcallIdValidator,
    minLengthField
}
