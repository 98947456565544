import { createSlice, PayloadAction, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import fetchStatus from "../types/fetchStatus";

import ApiClient from "../Helpers/ApiClient";

interface AvailableConnectorsState {
    connectors: any[]
    status: fetchStatus
    error: string | null
}

const initialState: AvailableConnectorsState = {
    connectors: [],
    status: fetchStatus.idle,
    error: null
}

export const fetchConnectors = createAsyncThunk('fetchConnectors', async (args, { getState }) => {
    const state = getState() as RootState;
    if (!state.user.token) throw new Error('API called with no token');
    return await ApiClient.makeSignedRequest(state.user.token, 'api/integrationConnector');
})

export const availableConnectorsSlice = createSlice({
    name: 'availableConnectors',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder
            .addCase(fetchConnectors.pending, (state, action) => {
                state.status = fetchStatus.loading
            })
            .addCase(fetchConnectors.fulfilled, (state, action) => {
                state.status = fetchStatus.succeeded
                state.connectors = action.payload.filter((connector: any) => {
                    return connector.name !== 'Webhooks'
                })
            })
            .addCase(fetchConnectors.rejected, (state, action) => {
                state.status = fetchStatus.failed

                state.error = 'Failed to fetch connectors'
            })
    }
})

// Other code such as selectors can use the imported `RootState` type
export const getAllConnectors = (state: RootState) => state.allConnectors.connectors

export default availableConnectorsSlice.reducer
