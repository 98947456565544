import React, {useState} from "react";
import {
    Alert,
    Box,
    Button, Checkbox, CopyToClipboard, FormField,
    Link, Modal,
    SpaceBetween
} from "@cloudscape-design/components";
import {ApiHelper} from "../../Helpers/apiHelper";
import {cloudcallIdValidator} from "../../Helpers/validator";
import CustomerIdSelector from "../CustomerIdSelector";
import AuthConnectorProps from "./ConnectorTypes";



const connectorName = "Salesforce";

const GetSalesforceAuthUrl = ({ setNotifications, clearNotifications, setIsLoading, connectorId }: AuthConnectorProps ) => {
    const [apiHelper] = useState(ApiHelper())
    const [modalVisible, setModalVisible] = useState(false);
    const [salesforcePluginRead, setSalesforcePluginRead] = useState(false);
    const [customerId, setCustomerId] = useState("");
    const closeModal = () => {
        setModalVisible(false);
        setSalesforcePluginRead(false);
    }
    const getButtonIsDisabled = () => {
        return (customerId.length === 0 || !cloudcallIdValidator(customerId));
    }

    const getUrl = async (ignoreWarning?: boolean) => {
        if(!ignoreWarning) {
            setModalVisible(true)
            return;
        }
        setIsLoading(true);
        setModalVisible(false);
        setSalesforcePluginRead(false);
        clearNotifications();
        const existingConnectors = await apiHelper.makeSignedRequest(`api/clientConnectors?customerId=${customerId}`)
            .then((res) => {
                return res?.data.filter((connector: any) => {
                    return connector?.clientConfiguration?.integrationName === connectorName
                });
            })
        const isUpdate = existingConnectors.length > 0;
        let targetUrl = `api/connectors/v1/oauth/login?connectorName=${connectorName}&customerId=${customerId}&connectorId=${connectorId}`;
        let customerMessage = `Please send the following url to the customer to create a new ${connectorName} integration:`;
        if (isUpdate) {
            const targetIntegrationId = existingConnectors[0].id;
            targetUrl = `${targetUrl}&existingConnectionId=${targetIntegrationId}`
            customerMessage = `Please send the following url to the customer to update their existing ${connectorName} integration:`;
        }
        apiHelper.makeSignedRequest(targetUrl).then((res) => {
            let error = (
                <Alert
                    statusIconAriaLabel="Info"
                    dismissible
                    //@ts-ignore
                    onDismiss={clearNotifications}
                    //@ts-ignore
                    type="error"
                    header="Error"
                    key={"errorFetching"}
                >
                    An error occurred fetching the url, please check the values entered or refresh and try again
                </Alert>
            )
            if (res && res.data.redirectUrl) {
                error = (<Alert
                    statusIconAriaLabel="Info"
                    dismissible
                    //@ts-ignore
                    onDismiss={clearNotifications}
                    //@ts-ignore
                    type="success"
                    header="Request Successful"
                    key={"success"}
                >
                    <SpaceBetween size={"xs"}>
                        <div key={"title"}>
                            {customerMessage}
                        </div>
                        <CopyToClipboard
                            copyButtonText="Copy"
                            copyErrorText="URL failed to copy"
                            copySuccessText="URL copied"
                            variant={"inline"}
                            //@ts-ignore
                            textToCopy={res.data.redirectUrl}
                            key={"copy"}
                        />
                    </SpaceBetween>
                </Alert>)
            }
            setNotifications(error);
        }).catch((e) => {
            let message = "An error occurred fetching the url, please check the values entered or refresh and try again";
            let header = "Invalid Request"
            setNotifications(<Alert
                statusIconAriaLabel="Error"
                dismissible
                //@ts-ignore
                onDismiss={clearNotifications}
                //@ts-ignore
                type="error"
                header={header}
                key={"errorFetching"}
            >
                {message}
            </Alert>)
        })
            .finally(() => {
                setIsLoading(false)
            })
    };

    return (
        <>
            <Modal
                onDismiss={closeModal}
                visible={modalVisible}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={closeModal}>Cancel</Button>
                            <Button variant="primary"
                                    disabled={!salesforcePluginRead}
                                    onClick={() => getUrl(true)}>
                                Get URL
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Salesforce Plugin"
            >
                <SpaceBetween size={"s"}>
                    <div>
                        Please ensure the customer has installed the Salesforce plugin correctly <strong>BEFORE </strong>
                        you send this link, for more details, or if you are not sure please contact the
                        <Link external={true} href={"mailto:IntegrationsTeam@cloudcall.com"}> integrations team</Link>
                    </div>
                    <div>
                        For more details on the plugin please see
                        <Link external={true} href={"https://cloudcall.atlassian.net/wiki/spaces/TECH/pages/108174311425/Package+installation+guide"}> this confluence page</Link>.
                    </div>
                    <div>
                        <strong>
                            It is vital that all steps, including the "Post Package Installation Steps" are followed correctly before the customer follows the authorisation link -
                            if this does not happen the sync may fail
                        </strong>
                    </div>
                    <Checkbox
                        onChange={({ detail }) =>
                            setSalesforcePluginRead(detail.checked)
                        }
                        checked={salesforcePluginRead}
                    >
                        The customer has the Salesforce plugin installed, and has followed the Post Package Installation Steps
                    </Checkbox>
                </SpaceBetween>
            </Modal>
            <SpaceBetween size={"m"} >
                <FormField
                    description="Customer ID"
                >
                    <CustomerIdSelector
                        initialValue={customerId}
                        onChange={setCustomerId}
                        invalid={!cloudcallIdValidator(customerId)}
                    />
                </FormField>
                <Button
                    variant={"primary"}
                    disabled={getButtonIsDisabled()}
                    onClick={()=>getUrl()}>
                    Get Auth URL
                </Button>
            </SpaceBetween>
        </>
    )

}

export default GetSalesforceAuthUrl;
