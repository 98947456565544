import {ContentLayout, Header, SpaceBetween} from "@cloudscape-design/components";
import ListAllConnectors from "../Components/ListAllConnectors";
import ListConnectorsByCustomerId from "../Components/ListConnectorsByCustomerId";
import GetAuthUrl from "../Components/GetAuthUrl";
import {useContext} from "react";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import {isRoleAdmin} from "../Helpers/role";
const Home = () => {
    const {tokenData} = useContext<IAuthContext>(AuthContext);
    const isAdmin = isRoleAdmin((tokenData || {}).role);
    return (
        <ContentLayout
            header={
                <Header variant="h1">
                    Connectors
                </Header>
            }
        >
            <SpaceBetween size={"m"}>
                <ListAllConnectors />
                <ListConnectorsByCustomerId />
                { isAdmin &&
                  <GetAuthUrl />
                }

            </SpaceBetween>

        </ContentLayout>
    );
}
export default Home;
