import {useContext} from "react";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import ApiClient from "./ApiClient";

export const ApiHelper = () => {
    const {token} = useContext<IAuthContext>(AuthContext);
    return {
        makeSignedRequest: async (path: string, method: string = "get", body?:any, headers?:any) => {
            return await ApiClient.makeSignedRequest(token, path, method, body, headers).then((data) => {
                return { data }
            });
        }

    }
}
